import { Col, Container, Row } from "react-bootstrap";
import "../scss/shared/app-banner.scss";

const AppBanner = () => (
  <div className="AppBanner">
    <Container fluid="sm" className="h-100">
      <Row className="justify-content-lg-end justify-content-between w-100 align-items-center h-100">
        <Col xs={6} md={4} lg={3} xl={2} className="contact-container justify-content-start justify-content-lg-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="16.292" height="16.322" viewBox="0 0 16.292 16.322" >
            <path id="Icon_feather-phone" data-name="Icon feather-phone" d="M17.958,14.1v2.231a1.487,1.487,0,0,1-1.621,1.487,14.717,14.717,0,0,1-6.418-2.283,14.5,14.5,0,0,1-4.462-4.462A14.717,14.717,0,0,1,3.174,4.621,1.487,1.487,0,0,1,4.654,3H6.885A1.487,1.487,0,0,1,8.372,4.279a9.549,9.549,0,0,0,.521,2.09,1.487,1.487,0,0,1-.335,1.569l-.944.944a11.9,11.9,0,0,0,4.462,4.462l.944-.944a1.487,1.487,0,0,1,1.569-.335,9.549,9.549,0,0,0,2.09.521,1.487,1.487,0,0,1,1.279,1.51Z" transform="translate(-2.417 -2.25)" fill="none" stroke="#c6cdd2" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" />
          </svg>
          <span className="mt-auto">031 001 3206</span>
        </Col>
        <Col xs={6} md={4} lg={3} xl={2} className="contact-container justify-content-end">
          <svg xmlns="http://www.w3.org/2000/svg" width="15.416" height="17" viewBox="0 0 15.416 17" >
            <path id="Icon_simple-email" data-name="Icon simple-email" d="M17.075,7.846a8.767,8.767,0,0,1-.283,1.9,5.5,5.5,0,0,1-.739,1.67A3.9,3.9,0,0,1,14.8,12.6a3.538,3.538,0,0,1-1.83.451,2.653,2.653,0,0,1-1.324-.324,2.267,2.267,0,0,1-.894-.917,2.835,2.835,0,0,1-2.4,1.2,2.439,2.439,0,0,1-2.094-1.126,3.686,3.686,0,0,1-.521-1.3,5.626,5.626,0,0,1-.068-1.7A7.905,7.905,0,0,1,6.189,6.8a5.665,5.665,0,0,1,.958-1.6A4.054,4.054,0,0,1,8.464,4.189a3.676,3.676,0,0,1,1.6-.356,4.981,4.981,0,0,1,.958.082,3.922,3.922,0,0,1,.744.223,4.021,4.021,0,0,1,.616.325c.188.121.377.246.565.374l-.465,5.354a1.78,1.78,0,0,0,.014.627.877.877,0,0,0,.191.387.688.688,0,0,0,.31.2,1.152,1.152,0,0,0,1.09-.215,2.323,2.323,0,0,0,.584-.744,4.789,4.789,0,0,0,.393-1.118,7.668,7.668,0,0,0,.172-1.392,8.894,8.894,0,0,0-.219-2.6,5.169,5.169,0,0,0-.962-2.012,4.469,4.469,0,0,0-1.725-1.3,6.262,6.262,0,0,0-2.513-.46,5.55,5.55,0,0,0-2.486.542,5.7,5.7,0,0,0-1.889,1.5,7.209,7.209,0,0,0-1.237,2.26,10.182,10.182,0,0,0-.521,2.815,9.361,9.361,0,0,0,.266,2.872A5.188,5.188,0,0,0,5,13.62,4.38,4.38,0,0,0,6.78,14.856a6.821,6.821,0,0,0,2.454.41,8.4,8.4,0,0,0,1.61-.173c.266-.053.517-.117.757-.187a5.7,5.7,0,0,0,.652-.234l.337,1.515a3.738,3.738,0,0,1-.73.361A7.025,7.025,0,0,1,11,16.8a8.417,8.417,0,0,1-1.8.2,9.448,9.448,0,0,1-3.285-.529A6.045,6.045,0,0,1,3.518,14.9a6.612,6.612,0,0,1-1.437-2.6,11.088,11.088,0,0,1-.4-3.609,11.005,11.005,0,0,1,.69-3.463A8.571,8.571,0,0,1,4.033,2.474,7.581,7.581,0,0,1,6.571.659,7.946,7.946,0,0,1,9.858,0a8.3,8.3,0,0,1,3.185.57,6.194,6.194,0,0,1,2.3,1.6,6.583,6.583,0,0,1,1.364,2.478A9.476,9.476,0,0,1,17.075,7.846ZM7.952,8.887a3.614,3.614,0,0,0,.187,1.792.961.961,0,0,0,.924.606,1.021,1.021,0,0,0,.317-.058,1.129,1.129,0,0,0,.364-.219,1.945,1.945,0,0,0,.37-.446,3.424,3.424,0,0,0,.34-.754l.363-4.16a2.171,2.171,0,0,0-.565-.074,1.954,1.954,0,0,0-.94.216,1.861,1.861,0,0,0-.666.633A3.718,3.718,0,0,0,8.2,7.463a8.831,8.831,0,0,0-.251,1.423Z" transform="translate(-1.67)" fill="#c6cdd2" />
          </svg>
          <span className="mt-auto">info@natedsystems.co.za</span>
        </Col>
      </Row>
    </Container>
  </div>
);

export default AppBanner;
