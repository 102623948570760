import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const EnterpriseResourcePlanning = () => {
  return (
    <div>
      <header className="header">
        <h1>ENTERPRISE RESOURCE PLANNING</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              All-in-one software solution designed to meet the needs of
              companies, regardless of their size (or budget). The unbeatable,
              seamless nature of Odoo helps businesses become more efficient by
              reducing redundant manual processes, which helps companies save
              countless hours of labor. Every module is interconnected to
              provide a fully-integrated experience from app to app, and users
              can automate many processes that would’ve otherwise required
              manual inputs into multiple applications. Odoo keeps all business
              functions in one place, allowing teams to collaborate with other
              departments from one unified platform in the most efficient way
              possible. Odoo is an innovative, user-friendly, open-source
              software that offers security features to business technology and
              software development communities all around the world. The Odoo
              market offers numerous modules and apps that are tailor-made for
              any and all business needs.
            </p>
            <h5>Key Features</h5>
            <ul>
              <li>User-friendliness</li>
              <li>Up-to-date and modern</li>
              <li>Integrates with third-party solutions</li>
              <li>Global support</li>
              <li>Less implementation cost</li>
              <li>Readymade apps</li>
              <li>Flexibility</li>
              <li>Highly modular design</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
