import { Col, Container, Row } from "react-bootstrap";
import agile from "../assets/images/agile.svg";
import respect from "../assets/images/respect.svg";
import support from "../assets/images/support.svg";
import usability from "../assets/images/usability.svg";
import AppCardW from "./app-card-w";

const AppChoose = () => (
  <Container className="pt-5" fluid="sm">
    <Row className="pt-5">
      <Col>
        <h5 className="text-center h-header mb-3">WHY US</h5>
        <p className="pb-5 text-center">
          We enable organizations to realize their business goals through an array of services from
          strategy to operations by improving customer experience, enhance business process, digital
          transformation and enterprise resource planning. We provide services in technology
          consulting, custom application implementation, maintenance of software and integration
          including IoT, AI, BI and Cloud Computing.
        </p>
      </Col>
    </Row>
    <Row className="pt-5 pb-5" xs={1} lg={2}>
      <AppCardW
        svg={respect}
        header="Respect of the client."
        description="We believe in a transparent and inclusive business to consumer relationship. The fundamental principle being respect and open communication for our clients. Moreover, Nated Systems prides itself in practicing a high level of professionalism which means: We value client communication and engagement in respect to betterment of our products and services"
      />
      <AppCardW
        svg={agile}
        header="Agile and Fast Working Style."
        description="Nated Systems has founded its business productivity model on an Agile working approach which is beneficial regarding bringing people, processes, connectivity and technology, time and place together to find the most appropriate and effective way of working to carry out a particular task. "
      />
      <AppCardW
        svg={support}
        header="Dedicated 24/7 support"
        description="Nated Systems provides around the clock monitoring for mission-critical, customer-facing applications. We employ skilled resources, ready to respond to and resolve incidents 24/7. Support calls are always answered by real people, and we provide resolution and post-incident reporting."
      />
      <AppCardW
        svg={usability}
        header="High Level of Usability."
        description="We as an enterprise have adopted a design with high usability guides users for the easiest and least labour-intensive route. This gives us a leverage to deeper understanding of users' contexts. This allows us accommodate consumer limitations, such as their environment, their distractions and cognitive understanding."
      />
    </Row>
  </Container>
);

export default AppChoose;
