import { Container } from "react-bootstrap";

export const Strategy = () => {
  return (
    <div>
      <header className="header">
        <h1>STRATEGY</h1>
      </header>
      <Container>
        <div>
          {/* <!-- left --> */}
          <div>
            <h4>
              We provide end-to-end services from strategy to business to
              technology consulting that are executable through our technology
              offerings.
            </h4>
          </div>

          {/* <!-- right --> */}
          <div>
            <p>
              Nated Systems’ Strategy and Transformation Consulting brings a
              fresh perspective to business transformation, anchored by Digital
              Change Lifecycle. Our approach emphasizes inclusive, outside-in
              techniques as well as access to expertise and innovation to
              explore the new digital business context, eliciting new market
              strategies, capability requirements and a digital value-creation
              agenda. Accelerate your digital transformation and bring guidance
              to your digital enterprise journey.
            </p>
            <p>
              We’ll partner with you to accelerate your digital transformation
              with our world-class expertise.
            </p>

            <h3>How can we help you</h3>
            <ul>
              <li>Strategic Alignment</li>
              <li>
                We execute strategies that will enable business to achieve
                sustainable growth, enhanced performance and managed risk. We
                align stakeholders around the transformational implications of
                their digital strategy, and develop an executable transformation
                roadmap.
              </li>

              <li>Strategy and innovation</li>
              <li>
                We translate strategy into executable transformation plans;
                create the business cases; manage organizational change; and
                architect.
              </li>

              <li>Strategic transformation</li>
              <li>
                we provide new opportunities to create value as you continuously
                monetize initiatives on your transformation roadmap. Success is
                measured by the economic value added to the business and the
                return on invested capital.
              </li>

              <li>Digital Strategy and Transformation</li>
              <li>
                We help companies thrive in the transformative age by refreshing
                themselves constantly, experimenting with new ideas and scaling
                successes.
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};
