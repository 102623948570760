import { Container } from "react-bootstrap";

const InfrastructureService = () => {
  return (
    <div>
      <header className="header">
        <h1>INFRASTRUCTURE SERVICES</h1>
      </header>
      <Container>
        <div>
          {/* <!-- left --> */}
          <div>
            <h4>
              Nated Systems delivery model for cloud services where customers
              purchase access to managed IT infrastructure over the internet.
            </h4>
          </div>

          {/* <!-- right --> */}
          <div>
            <p>
              We provide a platform on which software can be developed and
              deployed. The provision of basic cloud infrastructures like
              virtualized servers, storage, and networking in an on-demand
              model, which can easily scale up and down to meet demand.
            </p>
            <p>
              Our platform handles the complexity around operating systems and
              servers and leaves application developers free to concentrate on
              the business requirements of the software.
            </p>

            <h3>How can we help you</h3>
            <ul>
              <li>Uptime percentage offered in SLA </li>
              <li>Ease of scaling up or down in response to consumer needs </li>
              <li>
                Administration automation for deploying and managing virtual
                desktops and servers{" "}
              </li>
              <li>Preconfigured templates for virtual machines </li>
              <li>
                Monitoring tools providing alerts when problems are detected{" "}
              </li>
              <li>Compliance with security protocols </li>
              <li>Highest availability and security </li>
              <li>Multi-data centre </li>
              <li>Managed Hosting </li>
              <li>Back-up services </li>
              <li>Disaster recovery </li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default InfrastructureService;
