import { FunctionComponent } from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Nav, NavDropdown } from "react-bootstrap";

export interface NavLinkProps {
  to: string;
  text: string;
  scrolled?: boolean;
  isDropDownLink?: boolean;
}

const NavLink: FunctionComponent<NavLinkProps> = ({ to, text, scrolled, isDropDownLink }) => {
  const classState = scrolled ? "link-scrolled" : "";
  let linkType = <Nav.Link className={classState}>{text}</Nav.Link>;

  if (isDropDownLink) {
    linkType = <NavDropdown.Item>{text}</NavDropdown.Item>;
  }

  return <LinkContainer to={to}>{linkType}</LinkContainer>;
};

export default NavLink;
