import { Col, Container, Row, Card } from "react-bootstrap";
import "../scss/about/about-us.scss";
import vision from "../assets/images/vision.svg";
import mission from "../assets/images/mission.svg";
import values from "../assets/images/values.svg";

const AboutUsPage = () => {
  return (
    <div>
      <header className="header">
        <h1>ABOUT US</h1>
      </header>
      <Container fluid="sm" className="aboutusHeader">
        <Row className="pt-5 pb-5">
          <Col>
            <h5 className="text-center h-header">WHO WE ARE?</h5>
            <p className="text-center">
              Nated Systems is a leading-edge software provider and dedicated to improving business
              performance through digital transformation. We assist clients across targeted
              industries through consulting, system implementation, support and technical services
              to enhance digital business, workplace productivity, and customer experience through
              the effective use and adoption of technology.
            </p>
            <p className="text-center">
              Nated Systems enables organizations to realize their business goals through an array
              of services from strategy to operations by improving customer experience, enhance
              business process, digital transformation and enterprise resource planning. Nated
              Systems’ extensive partner network helps drive collaboration and leverage technology
              independence.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center justify-content-lg-start">
          <AboutCard
            icon={vision}
            header="VISION"
            description="We aspire to become a global leader in enhancing businesses productivity through digital means."
          />
          <AboutCard
            icon={mission}
            header="MISSION"
            description="To transform your organization, improve performance and respond quickly through digital transformation."
          />
          <AboutCard
            icon={values}
            header="VALUES"
            description="We measure our success and what we believe is important to us."
          />
        </Row>
      </Container>
      <ValuesSection />
      <MessageSection />
    </div>
  );
};

const AboutCard = ({ icon, header, description }: any) => (
  <Col xs={12} md={6} lg={4} className="mb-4 mb-lg-0">
    <Card className="align-items-center">
      <Card.Img variant="top" src={icon} className="pt-5 pb-3" />
      <Card.Body>
        <Card.Title className="text-center">{header}</Card.Title>
        <Card.Text className="text-center">{description}</Card.Text>
      </Card.Body>
    </Card>
  </Col>
);

const ValueCard = ({ img, header, description }: any) => (
  <Col md={6} xs={12} >
    <Row className="valueCard d-flex align-content-center m-4">
      <Col xs={3} className="d-flex">
        <svg xmlns="http://www.w3.org/2000/svg" width="454" height="500" viewBox="0 0 454 500">
          <path
            data-name="Polygon 1"
            d="M367.318,0a13,13,0,0,1,11.388,6.729l117.841,214a13,13,0,0,1,0,12.541l-117.841,214A13,13,0,0,1,367.318,454H132.682a13,13,0,0,1-11.388-6.729l-117.841-214a13,13,0,0,1,0-12.541l117.841-214A13,13,0,0,1,132.682,0Z"
            transform="translate(454) rotate(90)"
            fill="#fff"
          />
        </svg>
      </Col>
      <Col xs={9}>
        <h4>{header}</h4>
        <p>{description}</p>
      </Col>
    </Row>
  </Col>
);

const ValuesSection = () => {
  return (
    <div className="valuesRow pt-1 mt-5">
      <Container fluid="sm" className="pt-3">
        <h5 className="text-center h-header">ETHICS</h5>
        <Row className="pb-5 pt-3">
          <ValueCard
            img=""
            header="Respect"
            description="We believe that everyone should be respected as an individual"
          />
          <ValueCard
            img=""
            header="Innovation"
            description="We are coming up with ideas that make things happen"
          />
          <ValueCard img="" header="Integrity" description="We believe in doing what is right" />
          <ValueCard img="" header="Humanity" description="We care for our clients satisfaction" />
        </Row>
      </Container>
    </div>
  );
};

const MessageSection = () => {
  return (
    <div className="messageRow pt-4">
      <Container fluid="sm" className="pt-3">
        {/* <h5 className="text-center h-header">OUR MESSAGE</h5> */}
        <Row className="pb-5 pt-3">
          <h4 className="text-center text-uppercase">
            We are committed to drive technology innovation in the era of the Fourth Industrial
            Revolution, by providing best practices on developing IT strategy, governance, project
            management and digital solution in private and public sector.
          </h4>
        </Row>
      </Container>
    </div>
  );
};

export default AboutUsPage;
