import { Container } from "react-bootstrap";

export const GovernanceRiskCompliance = () => {
  return (
    <div>
      <header className="header">
        <h1>GOVERNANCE, RISK & COMPLIANCE</h1>
      </header>
      <Container>
        <div>
          <div>
            <p>
              Our core concept behind IT governance is making sure that
              organizations align business strategy with IT strategy. This means
              that the goal of IT governance is ultimately to ensure that the
              processes governing evaluation, selection, prioritization, and
              funding of competing IT investments are driven by the overall
              business. Our IT governance are accompanied by processes to manage
              risk across the enterprise and also to ensure compliance with
              multiple regulations. We improve visibility to company wide risk,
              improve employee efficiency by automating controls and
              streamlining testing, implement necessary paperwork and controls
              to ensure compliance, and reduce the time to audit.
            </p>

            <ul>
              <li>
                We provide a single point of reference for managing risk
                associated with regulation and the challenges of meeting
                regulatory requirements.
              </li>
              <li>
                We offer a variety of risk and regulatory services to the market
                that includes:
              </li>

              <li>Corporate governance</li>
              <li>
                Identifying governance gaps and recommending governance
                improvements.
              </li>
              <li>Enterprise risk management</li>
              <li>Maintaining operational excellence for long term success.</li>

              <li>Financial risk management</li>
              <li>
                Identifying, measuring and managing market risk, credit risk and
                insurance risk.
              </li>

              <li>Regulatory advisory</li>
              <li>
                Helping you stay on top of your compliance issues – both at home
                and abroad.
              </li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};
