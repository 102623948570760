import { Container } from "react-bootstrap";

export const ProgrammeManagement = () => {
  return (
    <div>
      <header className="header">
        <h1>BIG DATA & ANALYSIS</h1>
      </header>
      <Container>
        <div>
          <div>
            {/* <!-- left --> */}
            <div>
              <h4>
                Nated Systems help companies bridge the ever widening span
                between the overflow volume of complex data and optimize their
                ongoing operations and realize a greater digital reach
              </h4>
            </div>

            {/* <!-- right --> */}
            <div>
              <p>
                Smart homes, the Internet of Things, social media, mobile
                applications, and other technologies are generating an
                unprecedented amount of multistructured data. This "big data"
                has the potential to transform businesses and industries and to
                unlock tremendous value. It transforms how companies organize
                themselves, decide which technologies to use. The time has come
                to value data as a strategic asset that can help you win.
              </p>

              <p>
                We can help you apply analytics throughout your organization to
                grow, protect and optimize your business by harnessing the
                latest advanced technologies. The true value lies in embedding
                analytics deeply into business processes at the point where
                decisions are made – by human beings. We help you build the
                structures and processes that convert data into a strategic
                asset, aligning data capture and analytics within critical
                streams of the organization.
              </p>

              <h3>How can we help you</h3>
              <ul>
                <li>Create an agile and responsive finance operations:</li>
                <li>
                  New technologies and market entrants, changing customer needs
                  and behaviours, and exponentially growing data volumes create
                  a need for change. With our analytics we can help you to:
                  Monitor performance management; Reduce the costs of finance;
                  Speed up financial systems; Align people capabilities;
                  Anticipate financial risks
                </li>

                <li>Manage your risks to secure future success:</li>
                <li>
                  The need to secure sensitive data, to protect private
                  information and to manage data quality exists whether data
                  sets are big or small. With our analytics we can help you to:
                  Improve risk and internal audit processes and enable the
                  business to deliver even better products and services;
                  Simplify regulatory processes and improve quality; Detect the
                  next threat
                </li>

                <li>Unlock the value of your customers:</li>
                <li>
                  Technology has empowered consumers to be smarter, better
                  informed and more demanding than ever. With our analytics can
                  help you to: Attract and retain the right customers; Unlock
                  the full value of existing customers; Optimize your channel
                  mix
                </li>

                <li>Create value in human resources:</li>
                <li>
                  {" "}
                  can help to optimize resource planning as well as better
                  understand your employees, which in turn will support you in
                  achieving superior performance while reducing costs. Gain
                  employee insight and optimize resource allocation
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
