import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const GovernanceRiskComplianceSystem = () => {
  return (
    <div>
      <header className="header">
        <h1>GOVERNANCE, RISK, AND COMPLIANCE SYSTEM</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              Nated System digitizes and aligns an organization’s Governance, Risk and Compliance
              program with Strategy, to enable the governance, management, and assurance of risk,
              compliance, and performance across the enterprise. Monitor and manage the risks such
              as fraud, data theft, security threats, privacy breaches, and non-compliance that
              could negatively impact your enterprise performance objectives. Corporater’s risk and
              compliance management solutions are among the few that can be fully integrated into a
              performance management solution. Business Management Platform – Enabling An Integrated
              Approach to GRC We offer a Business Management Platform (BMP) that enables
              organizations to seamlessly connect the areas of governance, performance, risk, and
              compliance (GPRC). Highly flexible and quick to implement, all Corporater GRC
              solutions are built on the BMP and can be expanded to cover more expansive performance
              management needs or unlock synergies between risk and compliance functions.
            </p>
            <h3>Key Benefits</h3>
            <ul>
              <li>
                Eliminate Silos: Link the strategic, tactical, and operational layers of your
                business, eliminate silos, and drive business outcomes.
              </li>
              <li>
                Connected Enterprise: Establish a holistic, central point of governance, management,
                and assurance of the corporation’s performance, risk, and compliance.
              </li>
              <li>
                Informed Decision-making: Ensure the information flow empower executives and
                employees to make sound, timely decisions.
              </li>
              <li>
                Integrity and Sustainability: Develop a strong ethical work culture, make
                environmental and social impact, reduce misconduct, and use metrics to communicate
                the importance of business integrity.
              </li>
              <li>
                Unified Workforce: Have clearly communicated vision, values, and strategy to
                coordinate across departments and business units to ensure everyone is working
                towards shared goals.
              </li>
              <li>
                Business Impact: Move from data and insights to plans, decisions, and execution
                within a single platform, to realize business impact.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
