import { Container } from "react-bootstrap";

const PlatformService = () => {
  return (
    <div>
      <header className="header">
        <h1>PLATFORM SERVICES</h1>
      </header>
      <Container>
        <div>
          {/* <!-- left --> */}
          <div>
            <h4>
              Nated Systems provide a platform for building software delivered
              over the web.
            </h4>
          </div>

          {/* <!-- right --> */}
          <div>
            <p>
              Our platform offer a very good way to develop a scalable web
              application. PaaS provides a platform on which software can be
              developed and deployed. The platform handles the complexity around
              operating systems and servers and leaves application developers
              free to concentrate on the business requirements of the software.
            </p>
            <p>
              We provides services like middleware, development tools, business
              intelligence services, and other development infrastructure
            </p>

            <h3>How can we help you</h3>
            <ul>
              <li>Ease-of-building user interfaces</li>
              <li>Scalability</li>
              <li>Platform management overhead</li>
              <li>Workflow engine capability</li>
              <li>Services-enabled integration</li>
              <li>Development environment creation</li>
              <li>Upgrades and platform fixes</li>
            </ul>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default PlatformService;
