import { Col, Container, Row } from "react-bootstrap";
import "../scss/home/app-solutions.scss";

const AppSolutions = () => (
  <Container fluid="sm" className="pb-5">
    <Row className="pt-5">
      <h5 className="mb-3 text-center h-header">OUR SERVICES</h5>
      <p className="pb-5 text-center">
        We enable organizations to realize their business goals through an array of services from
        strategy to operations by improving customer experience, enhance business process, digital
        transformation and enterprise resource planning. We provide services in technology
        consulting, custom application implementation, maintenance of software and integration
        including IoT, AI, BI and Cloud Computing.
      </p>
    </Row>
    <Row>
      <Col sm={12} lg={4}>
        <div className="p-3 service-box solution-1 mb-4 mb-lg-0">
          <Row className="p-5">
            <Col className="d-flex align-items-center align-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="43.441"
                height="52.691"
                viewBox="0 0 43.441 52.691"
                className="digitalIcon"
              >
                <g id="Cursor" transform="translate(1.607 2.699)">
                  <path
                    className="black"
                    id="Path_4"
                    data-name="Path 4"
                    d="M40.52,26,14.31,10.73a2.61,2.61,0,0,0-3.91,2.61L14.54,43.4a2.62,2.62,0,0,0,4,1.83,2.27,2.27,0,0,0,.45-.38l7.23-7.56L34,48.88a2.5,2.5,0,1,0,4.16-2.78L30.4,34.51l9.75-3.79a2.33,2.33,0,0,0,.52-.27A2.62,2.62,0,0,0,40.52,26Zm-7.76,2.21-6,2.34A7.64,7.64,0,0,0,24,32.41l-4.46,4.67a.5.5,0,0,1-.86-.28L16.16,18.54a.5.5,0,0,1,.75-.51l15.92,9.3a.5.5,0,0,1-.07.9Z"
                  />
                  <path
                    className="green"
                    id="Union_1"
                    data-name="Union 1"
                    d="M6.407,24.345q-.392-.377-.748-.78-.06.031-.122.058a2.5,2.5,0,0,1-3.3-1.267l-1.22-2.74a2.5,2.5,0,0,1,1.267-3.3q.094-.042.189-.075a12.981,12.981,0,0,1,.239-4.055,2.5,2.5,0,0,1-.8-3.191L3.27,6.32A2.5,2.5,0,0,1,6.632,5.227q.054.027.106.057A13.018,13.018,0,0,1,9.762,3.259,2.5,2.5,0,0,1,11.7-.329L14.677-.7a2.5,2.5,0,0,1,2.781,2.829,12.979,12.979,0,0,1,3.93,1.3,2.5,2.5,0,0,1,3.841-.08L27.2,5.618A2.5,2.5,0,0,1,27,9.1a13.045,13.045,0,0,1,.64,1.492,2.459,2.459,0,0,1-2.7,3.26h-.22a2.35,2.35,0,0,1-1.81-1.579,8,8,0,0,0-8-5.29,8.09,8.09,0,0,0-7.5,7.889,8,8,0,0,0,2.5,5.881,2.448,2.448,0,0,1,.63,2.389,2.509,2.509,0,0,1-4.129,1.2Z"
                    transform="translate(-2.407 -1.985)"
                  />
                </g>
              </svg>
            </Col>
          </Row>
          <h6 className="text-center pb-1">DIGITAL SOLUTIONS</h6>
          <p className="text-center">
            Our digital solutions are designed to help businesses undertake their digital
            transformation journey. From strategic development and implementation to operational
            support, effectively show digital transformation success to our customers.
          </p>
        </div>
      </Col>
      <Col sm={12} lg={4}>
        <div className="p-3 service-box solution-2 mb-4 mb-lg-0">
          <Row className="p-5">
            <Col className="d-flex align-items-center align-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50.249"
                height="48.475"
                viewBox="0 0 50.249 48.475"
              >
                <g id="Cloud" transform="translate(-0.006 -0.025)">
                  <path
                    className="black"
                    data-name="Path 1"
                    d="M34.71,39H12.34A12.34,12.34,0,0,1,6.27,15.92v-.45A15.47,15.47,0,0,1,35.78,9a15.01,15.01,0,0,1-1.07,30Zm-13-34A10.49,10.49,0,0,0,11.26,15.47a10.82,10.82,0,0,0,.14,1.59l.31,2-1.91.71A7.34,7.34,0,0,0,12.34,34H34.71a10,10,0,0,0,0-20h-.25l-2.17.11-.59-1.82A10.44,10.44,0,0,0,21.74,5Z"
                  />
                  <path className="black" data-name="Path 2" d="M37.87,41.49Z" />
                  <rect
                    className="green"
                    data-name="Rectangle 1"
                    width="24"
                    height="5"
                    rx="2.5"
                    transform="translate(12.87 43.5)"
                  />
                </g>
              </svg>
            </Col>
          </Row>
          <h6 className="text-center pb-1">CLOUD SERVICES</h6>
          <p className="text-center">
            Cloud services help companies transform into a digital business by providing computing
            resources over the internet
          </p>
        </div>
      </Col>
      <Col sm={12} lg={4}>
        <div className="p-3 service-box solution-3 mb-4 mb-lg-0">
          <Row className="p-5">
            <Col className="d-flex align-items-center align-content-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="50"
                height="49.66"
                viewBox="0 0 50 49.66"
              >
                <path
                  className="black"
                  data-name="Path 1"
                  d="M41.83,0H8.17A8.17,8.17,0,0,0,0,8.17V29.83A8.17,8.17,0,0,0,8.17,38h2.55L7.34,46A2.5,2.5,0,1,0,12,47.68l4.76-11.17a.64.64,0,0,1,0-.07v-.07l.05-.17a1.447,1.447,0,0,1,.07-.26,2.45,2.45,0,0,0,0-.27,1,1,0,0,0,0-.17,2.46,2.46,0,0,0,0-.28V35a2.089,2.089,0,0,0-.08-.23.9.9,0,0,0-.08-.23,1.28,1.28,0,0,0-.11-.2,1.55,1.55,0,0,0-.12-.22l-.16-.19a1.22,1.22,0,0,0-.15-.17,1.39,1.39,0,0,0-.19-.15.9.9,0,0,0-.19-.15l-.19-.11a2.059,2.059,0,0,0-.26-.12h-.17L14.94,33H8.17A3.17,3.17,0,0,1,5,29.83V8.17A3.17,3.17,0,0,1,8.17,5H41.83A3.17,3.17,0,0,1,45,8.17V29.83A3.17,3.17,0,0,1,41.83,33H24.5a2.5,2.5,0,0,0,0,5H41.83A8.17,8.17,0,0,0,50,29.83V8.17A8.17,8.17,0,0,0,41.83,0Z"
                />
                <path
                  className="black"
                  data-name="Path 2"
                  d="M33,14.5A2.5,2.5,0,0,0,30.5,12h-18a2.5,2.5,0,0,0,0,5h18A2.5,2.5,0,0,0,33,14.5Z"
                />
                <rect
                  className="green"
                  data-name="Rectangle 1"
                  width="18"
                  height="5"
                  rx="2.5"
                  transform="translate(10 21)"
                />
              </svg>
            </Col>
          </Row>
          <h6 className="text-center pb-1">IT ADVISORY / CONSULTING</h6>
          <p className="text-center">
            Effectively manage IT governance and risk while also ensuring business and regulatory
            compliance. Nated Systems helps Customers analyze, audit and manage their governance,
            risk and compliance.
          </p>
        </div>
      </Col>
    </Row>
  </Container>
);
export default AppSolutions;
