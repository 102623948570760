import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const DocumentManagementSystem = () => {
  return (
    <div>
      <header className="header">
        <h1>DOCUMENT MANAGEMENT SYSTEM</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              Nated Systems offers on-premise or in-cloud document management
              for SMBs to enterprise organizations that are looking to
              streamline their business and document-intensive processes with
              workflow automation that includes eSignatures, form submissions,
              and compliance. Organizations of any size can control and track
              which users access, change, and share company documents - whether
              employees are remote or in an office. Leveraging the DMS solutions
              intelligently identify, categorize and process accounts payable
              invoices or any other low or high volume paper entering Content
              Central. Our solution is designed to maximize office productivity
              with automated features to intelligently manage business processes
              and documents. Our software solutions use optical character
              recognition (OCR) paired with AI to intelligently identify,
              categorize, and process scanned documents like accounts payable
              invoices, tax forms, new hire documents, and more. Automated
              workflows and integrations with 3rd party business software
              systems facilitates data lookups, eliminates the need for
              repetitive data entry, and ensures data integrity across systems,
              regardless of industry or department.
            </p>
            <h5>Key Features</h5>
            <ul>
              <li>
                Content Central’s robust workflow engine allows documents to be
                automatically routed for review, approval, revisions and
                collaboration.
              </li>
              <li>
                Intelligently store and classify your documents so they are
                easily found when and where you need them - even if you’re on
                the go.
              </li>
              <li>
                Securely send documents for eSignature and forms to users within
                and without your organization - all while meeting compliance.
              </li>
              <li>
                Eliminate redundant data entry and tedious cross-system work by
                integrating Content Central with your existing 3rd party
                business solutions.
              </li>
              <li>
                Access Controls and System Auditing ensure you meet HIPAA, SOX,
                FERPA, ISO compliance requirements with ease.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
