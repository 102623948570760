import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const CustomerRelationManagement = () => {
  return (
    <div>
      <header className="header">
        <h1>CUSTOMER RELATION MANAGEMENT</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              Nated Systems help companies bridge the ever widening span between
              the overflow volume of complex data and optimize their ongoing
              operations and realize a greater digital reach. Our CRM stresses
              good ongoing relationships with customers. It holds that
              maintaining these relationships drives growth and profitability.
              By implementing our CRM solutions will increase your profitability
              by streamlining processes in your sales, marketing, and service
              divisions, and letting you focus on building and maintaining the
              customer relationships that matter. Our CRM solution is a
              composite platform where everything crucial to developing,
              improving, and retaining your customer relationships is stored.
              Without the support of an integrated CRM solution, you may miss
              growth opportunities and lose revenue because you’re not
              optimising your operating processes or making the most of your
              customer relationships or sales leads
            </p>
            <h3>CRM Features & Capabilities</h3>
            <ul>
              <li>
                Customer Service: Knowledge bases, Ticketing systems, Live chat,
                Customer, support portals
              </li>
              <li>
                Sales: Creation of quotes, Order processing, Updating contact
                information, Sending e-mails, Sales forecasting, Evaluating
                employee performance
              </li>
              <li>
                Marketing Automation: Email marketing, Lead management, Lead
                scoring and qualification, Landing page creation, Web forms,
                Marketing analytics
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
