import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const CustomApplicationDevelopment = () => {
  return (
    <div>
      <header className="header">
        <h1>CUSTOM APPLICATION DEVELOPMENT</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              We offer the full competence designing, creating, deploying and
              maintaining software for a specific set of users, functions or
              organizations. Nated Systems application development and
              maintenance service is always in tune with the evolving business
              requirements and acquires an IT framework that can keep up the
              pace of our clients. Our deep-rooted domain knowledge and
              experience enables us to operate in complex application
              environments and handle client crucial applications efficiently.
              Our team implements the agile development process to deploy the
              solution, adhering to the best industry standards and practices.
              Our team possesses the capability to design and develop technology
              products that can streamline business processes and grow revenue.
            </p>
            <h5>Key Features</h5>
            <ul>
              <li>
                Transform legacy applications to leverage emerging technologies
              </li>
              <li>
                Conduct in-depth portfolio analysis to discover growth
                opportunities
              </li>
              <li>
                Enable scalable, and efficient processes with cloud migration
              </li>
              <li>
                Mobile development services for integrated software systems
              </li>
              <li>
                Develop highly customized workflows for multi-channel content
                distribution
              </li>
              <li>
                Enhance digital capabilities to unlock the full potential of
                IoT, AI, and mobile solutions
              </li>
              <li>
                Manages critical software applications development for leading
                companies, helping customers reduce cost with zero downtime.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
