import { useRoutes } from "react-router-dom";
import {
  HomePage,
  AboutusPage,
  ContactPage,
  Industries,
  InfrastructureService,
  PlatformService,
  SoftwareService,
  ArtificialIntelligence,
  BigData,
  GovernanceRiskCompliance,
  InternetOfThings,
  ProgrammeManagement,
  Strategy,
  CustomApplicationDevelopment,
  CustomerRelationManagement,
  DocumentManagementSystem,
  EnterpriseArchitecture,
  EnterpriseResourcePlanning,
  GovernanceRiskComplianceSystem,
  ProjectManagement,
  PerformanceManagement,
  ServiceManagementSystem,
} from "../pages";

const WebRoutes = () => {
  const routes = useRoutes([
    { path: "/", element: <HomePage /> },
    { path: "/about-us", element: <AboutusPage /> },
    { path: "/contact-us", element: <ContactPage /> },
    { path: "/industries", element: <Industries /> },

    { path: "/cloud/infrastructure-services", element: <InfrastructureService /> },
    { path: "/cloud/platform-services", element: <PlatformService /> },
    { path: "/cloud/software-services", element: <SoftwareService /> },

    { path: "/advisory/artificial-intelligence", element: <ArtificialIntelligence /> },
    { path: "/advisory/big-data-analytics", element: <BigData /> },
    { path: "/advisory/governance-risk-compliance", element: <GovernanceRiskCompliance /> },
    { path: "/advisory/internet-of-things", element: <InternetOfThings /> },
    { path: "/advisory/program-management", element: <ProgrammeManagement /> },
    { path: "/advisory/strategy", element: <Strategy /> },

    { path: "/digital/custom-application-development", element: <CustomApplicationDevelopment /> },
    { path: "/digital/customer-relation-management", element: <CustomerRelationManagement /> },
    { path: "/digital/document-management-system", element: <DocumentManagementSystem /> },
    { path: "/digital/enterprise-architecture", element: <EnterpriseArchitecture /> },
    { path: "/digital/enterprise-resource-planning", element: <EnterpriseResourcePlanning /> },
    { path: "/digital/performance-management", element: <PerformanceManagement /> },
    { path: "/digital/project-management", element: <ProjectManagement /> },
    { path: "/digital/service-management-system", element: <ServiceManagementSystem /> },
    { path: `/digital/governance-risk-compliance-system`, element: <GovernanceRiskComplianceSystem /> },
  ]);
  return routes;
};

export default WebRoutes;
