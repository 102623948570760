import { Col, Row } from "react-bootstrap";
import "../scss/home/app-card.scss";

const AppCardW = ({ svg, header, description }: any) => (
  <Col xs={12} lg={6} className="mb-4 mb-lg-0">
    <Row>
      <Col md={2} className="d-flex align-content-start mb-4 mb-md-0">
        <div className="cardImg">
          <img src={svg} alt="icon" />
        </div>
      </Col>
      <Col md={10}>
        <h6 className="text-center text-md-start ">{header}</h6>
        <p className="text-left text-md-start "> {description}</p>
      </Col>
    </Row>
  </Col>
);

export default AppCardW;
