import { NavLinkProps } from "../components/subcomponents/nav-link";

export const digitalLinks: NavLinkProps[] = [
  { to: "/digital/custom-application-development", text: "Custom Application Development", },
  { to: "/digital/customer-relation-management", text: "Customer Relation Management", },
  { to: "/digital/document-management-system", text: "Document Management System", },
  { to: "/digital/enterprise-architecture", text: "Enterprise Architecture" },
  { to: "/digital/enterprise-resource-planning", text: "Enterprise Resource Planning", },
  { to: "/digital/governance-risk-compliance-system", text: "Governance Risk Compliance", },
  { to: "/digital/performance-management", text: "Performance Management" },
  { to: "/digital/project-management", text: "Project Management" },
  { to: "/digital/service-management-system", text: "Service Management System", },
];

export const cloudLinks: NavLinkProps[] = [
  { to: "/cloud/software-services", text: "Software Services" },
  { to: "/cloud/platform-services", text: "Platform Services" },
  { to: "/cloud/infrastructure-services", text: "Infrastructure Services" },
];

export const advisoryLinks: NavLinkProps[] = [
  {
    to: "/advisory/governance-risk-compliance",
    text: "Governance, Risk & Compliance",
  },
  { to: "/advisory/strategy", text: "Strategy" },
  { to: "/advisory/internet-of-things", text: "Internet of Things" },
  { to: "/advisory/big-data-analytics", text: "Big Data & Analytics" },
  { to: "/advisory/program-management", text: "Program Management" },
  { to: "/advisory/artificial-intelligence", text: "Artificial Intelligence" },
];
