import AppChoose from "../components/app-choose-us";
import AppHeader from "../components/app-header";
import AppSolutions from "../components/app-solutions";

const HomePage = () => (
  <div>
    <AppHeader></AppHeader>
    <AppSolutions></AppSolutions>
    <AppChoose></AppChoose>
  </div>
);

export default HomePage;
