import { BrowserRouter as Router } from "react-router-dom";

import "./scss/App.scss";
import AppNav from "./components/app-nav";
import AppFooter from "./components/app-footer";
import WebRoutes from "./web-router/routes";

function App() {
  return (
    <div className="App">
      <Router>
        <div className="app-container">
          <AppNav></AppNav>
          <WebRoutes />
        </div>
        <AppFooter></AppFooter>
      </Router>
    </div>
  );
}

export default App;
