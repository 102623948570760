import { Container } from "react-bootstrap";

const SoftwareService = () => {
  return (
    <div>
      <header className="header">
        <h1>SOFTWARE SERVICES</h1>
      </header>
      <Container>
        {/* <!-- left --> */}
        <div>
          <h4>
            Nated Systems IoT Services has the expertise to help you build IoT
            capabilities that can increase operational efficiency and increase
            Revenue
          </h4>
        </div>

        {/* <!-- right --> */}
        <div>
          <p>
            We hosts applications for customers and make the application
            available to them via the internet. Our business applications,
            including email and collaboration, customer relationship management
            (CRM), billing/payroll processing, enterprise resourcing planning
            (ERP), content management, and document editing and management. We
            hosts applications for customers and make the application available
            to them via the internet. Our business applications, including email
            and collaboration, customer relationship management (CRM),
            billing/payroll processing, enterprise resourcing planning (ERP),
            content management, and document editing and management. The SaaS
            model can reduce costs for IT infrastructure. It can also minimize
            some of the time spent on management tasks, because a SaaS provider
            is responsible for upgrades and management.
          </p>
          <p>
            The SaaS model can reduce costs for IT infrastructure. It can also
            minimize some of the time spent on management tasks, because a SaaS
            provider is responsible for upgrades and management.
          </p>

          <h5>How can we help you</h5>
          <ul>
            <li>
              Stay up to date with the latest releases and unlimited upgrades{" "}
            </li>
            <li>Reduce capital expenditures and eliminate up-front costs </li>
            <li>
              Free up internal resources and let our administrators do the work{" "}
            </li>
            <li>
              Quickly scale and only pay for what you need, when you need it{" "}
            </li>
            <li>Secure, scalable, reliable and always available IT </li>
            <li>Require zero infrastructure to get up and running. </li>
            <li>Integrates with other web-based applications. </li>
            <li>Guaranteed 99.9% availability </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};

export default SoftwareService;
