import "../scss/contact/app-contact.scss";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import FormError from "../components/subcomponents/form-error";
import { useState } from "react";

interface FormData {
  fullname: string;
  phone: string;
  email: string;
  message: string;
}

const schema = yup.object({
  fullname: yup.string().required("Full Name is required"),
  phone: yup.string().required("Phone is required").matches(/^\+?[0]{1}[0-9]{2}[0-9]{3}[0-9]{4}$/, "Phone number is not valid"),
  email: yup.string().email("Email is invalid").required("Email is required"),
  message: yup.string().required("Message is required").min(30, "Message must be at least 30 characters long"),
});

const ContactPage = () => {
  // const apiUrl = "http://localhost:5001/natedsystems-3fa2f/us-central1/sendMail";
  const apiUrl = "https://us-central1-natedsystems-3fa2f.cloudfunctions.net/sendMail";

  //form state
  const { register, reset, handleSubmit, formState: { errors, isValid }, } = useForm<FormData>({ resolver: yupResolver(schema) });
  const [formLoading, setFormLoading] = useState(false);

  const sendMail = async (data: FormData, e: any) => {
    e.preventDefault();
    setFormLoading(true);
    if (isValid) {
      toast.promise(axios.post(apiUrl, data), {
        loading: "sending email...",
        success: () => {
          reset();
          setFormLoading(false);
          return "email sent successfully";
        },
        error: () => {
          setFormLoading(false);
          reset({}, { keepValues: true });
          return "email failed to send";
        },
      });
    } else {
      reset({}, { keepValues: true });
      setFormLoading(false);
      console.error("Form is invalid"); //TODO: add toast message
    }
  };
  return (
    <div>
      <header className="header">
        <h1>CONTACT US</h1>
      </header>
      <div className="pt-5 pb-5 mt-1 mb-1 contact-row ">
        <Container>
          <Row className="justify-content-center align-items-center ">
            <Col lg={10} className="contact-box">
              <Row className="align-items-stretch">
                <Col xs={12} lg={6} className="contact-box-left d-flex align-items-center order-last order-lg-first" >
                  <p className="contact-p text-center w-100">
                    <span className="number">197</span> North Ridge Road
                    <br />
                    Mazar House
                    <br />
                    Morningside
                    <br />
                    Durban(South Africa) <span className="number"> 4001 </span>
                    <br />
                    <a href="mailto:info@natedsystems.co.za"> info@natedsystems.co.za </a>
                    <br />
                    <a href="tel:+27310013206" className="number"> +27 31 001 3206 </a>
                    <br />
                  </p>
                </Col>
                <Col xs={12} lg={6} className="contact-box-right p-4">
                  <h5 className="text-center h-header pb-2 mb-2">TALK TO US</h5>
                  <Form onSubmit={handleSubmit(sendMail)}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="email" {...register("email")} placeholder="Email" />
                      <FormError error={errors.email} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="text" {...register("fullname")} placeholder="Full Name" />
                      <FormError error={errors.fullname} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control type="text" {...register("phone")} placeholder="Phone" />
                      <FormError error={errors.phone} />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Text as="textarea" {...register("message")} rows={4} placeholder="Message" />
                      <FormError error={errors.message} />
                    </Form.Group>
                    <Row className="align-content-center">
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-50 text-center"
                        disabled={formLoading}
                      >
                        {formLoading ? "Sending..." : "Send"}
                      </Button>
                      <Toaster />
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
      <div className="pt-0 pb-0">
        <Maps />
      </div>
      <div className="pt-5 pb-5 con-message">
        <h2 className="text-light text-center">
          IN A WORLD OF TECHNOLOGY, WE MAKE A DIFFERENCE.
        </h2>
      </div>
    </div>
  );
};

const containerStyle = {
  width: "100%",
  height: "400px",
};

const center = {
  lat: -29.8207305,
  lng: 31.008631,
};
const marker = {
  lat: -29.8207305,
  lng: 31.008631,
};

const Maps = () => (
  <LoadScript googleMapsApiKey="AIzaSyBWOMyEmxZVyeidLLRrsdIH-Mb_zAaF7cM">
    <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={15}>
      <Marker position={marker}></Marker>
    </GoogleMap>
  </LoadScript>
);
export default ContactPage;
