import { Container } from "react-bootstrap";

export const ArtificialIntelligence = () => {
  return (
    <div>
      <header className="header">
        <h1>ARTIFICIAL INTELLIGENCE</h1>
      </header>
      <Container>
        <div>
          <div>
            {/* <!-- left --> */}
            <div>
              <h4>
                Artificial intelligence (AI) is transforming business. Our team
                work with you to incorporate the robotic, intelligent and
                autonomous capabilities that will transform and innovate the way
                you operate and compete in the digital world.
              </h4>
            </div>

            {/* <!-- right --> */}
            <div>
              <p>
                To realize value from AI, it's a set of technologies and
                building blocks, all using data to unlock intelligent value
                across industries and business functions. Our consulting
                Services will help you leverage AI to drive transformation your
                workflows and technology. We help businesses leverage the
                potential of their massive data and convert them into
                decision-driven insights with our AI consulting services. Our AI
                consulting experts and data scientists team ambition are to
                build innovative solutions that can transform companies’
                processes. Our consulting team is ready to help you fully
                realize business benefits from AI.
              </p>

              <h3>How can we help you</h3>
              <ul>
                <li>
                  We help you predict your customer behavior and outcomes and
                  grow your business in the right direction.{" "}
                </li>
                <li>
                  We create algorithms that maximize accuracy and reduce errors
                  by learning new things from collected data.{" "}
                </li>
                <li>
                  We use Natural Language Processing to develop custom AI
                  applications that can extract meaning from structured and
                  unstructured data to perform customer-centric initiatives.{" "}
                </li>
                <li>
                  We leverage robotic, intelligent and autonomous capabilities
                  to transform operations through automation.{" "}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};
