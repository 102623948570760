import { Col, Container, Row } from "react-bootstrap";
import "../scss/shared/app-footer.scss";
import facebook from "../assets/images/social_icons/facebook.svg";
import instagram from "../assets/images/social_icons/instagram.svg";
import linkedin from "../assets/images/social_icons/linkedin.svg";
import twitter from "../assets/images/social_icons/twitter.svg";

const AppFooter = () => (
  <div className="app-footer">
    <Container>
      <BottomSection />
    </Container>
  </div>
);

const MidSection = () => (
  <Row className="justify-content-between align-items-center h-100">
    <Col lg={4}>
      <p>
        <span className="number">80</span> Fyfe RD Morningside <br />
        Durban(South Africa) <span className="number">40001</span> <br /> <br />
        <a href="mailto:info@natedsystems.co.za">info@natedsystems.co.za</a> <br />
        <a href="tel:+27310013206" className="number">
          +27 31 001 3206
        </a>{" "}
        <br />
      </p>
    </Col>
    <Col lg={8}></Col>
  </Row>
);

const BottomSection = () => (
  <Row className="justify-content-between align-items-center pt-3">
    <Col className="text-start">Copyright {new Date().getFullYear()}</Col>
    <Col className="text-center">Privacy Terms</Col>
    <Col sm={12} lg={4} className="text-end">
      <Row className="social-icons row-cols-6 justify-content-lg-end justify-content-center pt-4 pt-lg-3">
        <Col>
          <a target="_blank" rel="noreferrer" href="https://www.facebook.com/natedsystems">
            <img src={facebook} alt="facebook" />
          </a>
        </Col>
        <Col>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/natedsystems">
            <img src={instagram} alt="instagram" />
          </a>
        </Col>
        <Col>
          <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/company/nated-systems">
            <img src={linkedin} alt="linkedin" />
          </a>
        </Col>
        <Col>
          <a target="_blank" rel="noreferrer" href="https://www.twitter.com/natedsystems">
            <img src={twitter} alt="twitter" />
          </a>
        </Col>
      </Row>
    </Col>
  </Row>
);

export default AppFooter;
