import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const PerformanceManagement = () => {
  return (
    <div>
      <header className="header">
        <h1>PERFORMANCE MANAGEMENT</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              With Corporater Performance Management software, you can manage,
              visualize, share, and collaborate at all levels of the
              organization with dashboards, smart KPIs, strategy maps, and
              initiatives. With the holistic and individual levels of goals and
              performance clearly measured, visualized and communicated, you can
              easily achieve board-level reporting, drive actions towards future
              development, as well as ensure strategic alignment of operations
              throughout the organization. Enable your organization to manage
              responsiveness and recovery, communicate the context for change,
              and share the definitions of success. Corporater provides
              visibility to performance across the entire organization and helps
              decision-makers to analyze problems and make business decisions
              within the business context. Additionally, one can review
              processes for future development, and assess and communicate risk
              factors. With Corporater, you can introduce lean-transformation
              programs, or other initiatives for corporate restructuring,
              continuous improvement or business continuity, all from within the
              same platform.
            </p>
            <h5>Key Features</h5>
            <ul>
              <li>
                KPIs, Dashboards, and Analytics: Visualize, benchmark, and
                analyze critical management information within business context.
                Automate data collection without technical complexity.
              </li>
              <li>
                Corporate Performance Management: Transform your financial
                planning, forecasting, and budgeting to strategic activities
                that drive your financial performance forward.
              </li>
              <li>
                Employee Performance Management: Align employee goals, training,
                and incentives with your business strategy and link compensation
                to targeted metrics.
              </li>
              <li>
                Project & Portfolio Management: Manage multiple projects within
                single view and monitor KPIs of all projects that your
                organization is undertaking.
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
