import { FunctionComponent } from "react";
import { Col } from "react-bootstrap";
import NavLink, { NavLinkProps } from "./nav-link";

interface DropdownColumnProps {
  title: string;
  links: NavLinkProps[];
}

const DropdownColumn: FunctionComponent<DropdownColumnProps> = ({ title, links }) => {
  return (
    <Col md={4}>
      <ul className="multi-column-dropdown">
        <li><b>{title}</b></li>
        {links.map((link, index) => (<li><NavLink key={index} isDropDownLink {...link} /></li>))}
      </ul>
    </Col>
  );
};

export default DropdownColumn;
