import { Col, Container, Row } from "react-bootstrap";
import financial from "../assets/images/industries/financial_services.jpg";
import education from "../assets/images/industries/education.jpg";
import health_care from "../assets/images/industries/health_care.jpg";
import manufacturing from "../assets/images/industries/manufacturing.jpg";
import public_sector from "../assets/images/industries/public_sector.jpg";
import telecommunications from "../assets/images/industries/telecommunications.jpg";
import retail from "../assets/images/industries/retailjpg.jpg";
import transport from "../assets/images/industries/transport.jpg";

export const Industries = () => {
  return (
    <div>
      <header className="header">
        <h1>INDUSTRIES</h1>
      </header>
      <Container>
        <Col className="mt-2 mb-5">
          We build industry solutions that dramatically increase sales, revenue, customer engagement
          and employee productivity.
        </Col>
        <FinancialServices />
        <RetailAndConsumers />
        <Manufacturing />
        <PublicSector />
        <Healthcare />
        <Education />
        <Transportation />
        <Telecommunications />
      </Container>
    </div>
  );
};

const FinancialServices = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "first", span: 4 }}>
        <img src={financial} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "last", span: 8 }}>
        <h5 className="text-uppercase">Financial Services</h5>
        <p>
          We automate to innovate in financial services and deliver enterprise-scale solutions.
          Nated Systems provide an exceptional experiences for your clients to drive growth and
          retention. Rapid technological change is already impacting the Financial Services
          industry. Data security and improving the customer experience are now a top priority for
          banks, wealth management, and insurance providers.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>Accelerate innovation and time to market</li>
          <li>Provide superior customer experiences</li>
          <li>Mitigate risk and protect data privacy</li>
          <li>Dramatically reduce cost and time</li>
        </ul>
      </Col>
    </Row>
  );
};

const RetailAndConsumers = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "last", span: 4 }}>
        <img src={retail} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "first", span: 8 }}>
        <h5 className="text-uppercase">Retail And Consumers</h5>

        <p>
          Our specialists deliver ideas and solutions embedded with deep industry knowledge to our
          retail &amp; consumer clients. Our understanding of key industry issues is at the heart of
          our delivery of services to clients.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>Collaboration between manufacturers and retailers will lead to success.</li>
          <li>Gaining market share and revenue growth.</li>
        </ul>
      </Col>
    </Row>
  );
};

const Manufacturing = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "first", span: 4 }}>
        <img src={manufacturing} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "last", span: 8 }}>
        <h5 className="text-uppercase">Manufacturing</h5>

        <p>
          Nated Systems works with manufacturers to design and deploy digital solutions that empower
          employees to provide support that delights customers and partners. We automate and create
          a smart manufacturing. Reimagine manufacturing operations through process automation.
          Innovate on top of legacy systems to automate manual processes and deliver products faster
          than ever before.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>Accelerate product innovation and production efficiency</li>
          <li>Automate essential processes in your supply chain</li>
          <li>Manage risk, compliance and quality control</li>
          <li>
            We combine analytics, AI, and IoT to connect data and create actionable insights the
            drive real business value.
          </li>
          <li>
            Define your strategic direction and implement innovative digital platforms to make your
            organization more agile and interconnected.
          </li>
        </ul>
      </Col>
    </Row>
  );
};

const PublicSector = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "last", span: 4 }}>
        <img src={public_sector} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "first", span: 8 }}>
        <h5 className="text-uppercase">Public Sector</h5>
        <p>
          Nated Systems placed to support government’s four-pronged “e-Government” strategy of
          digital readiness, digital development, building the digital future and creating digital
          opportunities. Digitize and automate essential services to better serve citizens. Nated
          Systems enables you to rapidly innovate, while reducing costs and protecting regulatory
          compliance.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>Exceed citizen expectations and boost staff productivity</li>
          <li>Modernize your IT landscape to accelerate digital innovation</li>
          <li>Automate essential services to meet regulatory requirements</li>
        </ul>
      </Col>
    </Row>
  );
};

const Healthcare = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "first", span: 4 }}>
        <img src={health_care} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "last", span: 8 }}>
        <h5 className="text-uppercase">Healthcare</h5>
        <p>
          We Improve business and patient outcomes through automation. Connect your people and
          systems through agile process applications to improve the patient experience while
          reducing costs and protecting regulatory compliance. With the rapid growth of technologies
          and applications in healthcare, it will become easier and easier to create digital assets
          and gather digital data. Quality Designs is helping the health industry to better manage
          this digital health transformation.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>Accelerate service provision and unlock new efficiencies</li>
          <li>Modernize IT to transform both patient and staff experiences</li>
          <li>Mitigate risk and maintain regulatory compliance</li>
        </ul>
      </Col>
    </Row>
  );
};

const Education = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "last", span: 4 }}>
        <img src={education} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "first", span: 8 }}>
        <h5 className="text-uppercase">Education</h5>
        <p>
          <strong> Intelligent automation for smart education</strong>
          In our world today, students are digital natives, and institutions demand nothing short of
          complete digital transformation. Transform operations to exceed student and staff
          expectations by delivering instantaneous and personalized digital services that make life
          easier for everyone on campus.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>Create better student and staff experiences</li>
          <li>Accelerate IT service delivery across the campus</li>
          <li>Innovate on top of legacy systems without disruption</li>
        </ul>
      </Col>
    </Row>
  );
};

const Transportation = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "first", span: 4 }}>
        <img src={transport} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "last", span: 8 }}>
        <h5 className="text-uppercase">Transportation</h5>
        <p>
          Transportation and hospitality businesses face increasing competitive pressure to provide
          the best customer experience, build loyalty and expand services. Whether helping people or
          goods reach their destinations, industry leaders are leveraging new technologies to
          modernize aging infrastructure and outdated processes, as well as embracing connected
          platforms to help them deliver differentiated services and experiences that delight
          travelers.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li>
            Airlines &amp; Airports: We provide solutions that boost efficiency while improving the
            passenger experience.
          </li>
          <li>
            Rail &amp; Mass Transit: We increase the capacity, reliability and safety for the
            movement of both passengers and goods.
          </li>
          <li>
            Maritime &amp; Ports: We help by transforming to a “smart port” model, the ports of the
            future will be better connected, more efficient and more integrated with their
            neighboring communities.
          </li>
          <li>
            Freight &amp; Logistics: We offer an end-to-end visibility into the supply chain as
            consumer expectations change.
          </li>
        </ul>
      </Col>
    </Row>
  );
};

const Telecommunications = () => {
  return (
    <Row className="mt-5 mb-5">
      <Col xs={{ span: 12, order: "first" }} lg={{ order: "last", span: 4 }}>
        <img src={telecommunications} alt="financial services" width="100%" height="100%" />
      </Col>
      <Col xs={{ span: 12, order: "last" }} lg={{ order: "first", span: 8 }}>
        <h5 className="text-uppercase">Telecommunications</h5>
        <p>
          Nated Systems offers products and solutions across an all levels of the organization. All
          these products and solutions rely on a solid connectivity infrastructure as content,
          distribution and streaming video services converge. We partner with leaders in the
          telecoms industry that can&nbsp;provide&nbsp;all the links you need to connect all aspects
          of your business. With a focus on digital transformation, we help telecom companies
          incubate new business models that leverage technologies such as 5G, IoT and blockchain.
          Nated Systems uses agile methodologies to help customers speed the development of new
          products and engage in product rationalization to better align with network and strategy.
          Our strategic partnership with telecoms industry gives us unparalleled access to
          technology and expertise that can deliver innovative solutions.
        </p>

        <h6>How we can help</h6>
        <ul>
          <li> the customer experience </li>
          <li>Implement software-defined networks </li>
          <li>Adopt new communications platforms </li>
          <li>Implement managed security services </li>
        </ul>
      </Col>
    </Row>
  );
};
