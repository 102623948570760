import { Col, Container, Row } from "react-bootstrap";

export const ProjectManagement = () => {
  return (
    <div>
      <header className="header">
        <h1>PROJECT MANAGEMENT</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              Nated Systems provides a comprehensive solution for managing projects that covers
              request tracking, project planning, project tracking, project accounting, and
              portfolio management. Users can collate project requests from multiple sources like
              emails, forms, and spreadsheets, and then prioritize them based on business KPIs. They
              can build dynamic project plans that automatically adjust with auto scheduling tools,
              support for inter-project dependencies, and multiple assigned resources per task.
              Teams can track with milestones, RAG health indicators, critical path analysis,
              baseline, and EVA methods, as well as automatic email alerts. Project accounting tools
              provide visibility into budget, receivables, and profitability, with tools for profit
              and margin tracking, revenue forecasting, and custom KPIs. Portfolio management
              provides aggregated information across projects on custom dashboards.
            </p>
            <h5>Key Features</h5>
            <ul>
              <li>Project Request Tracking</li>
              <li>Project Planning</li>
              <li>Project Tracking</li>
              <li>Project Accounting</li>
              <li>Portfolio Management</li>
              <li>Resource Management</li>
              <li>Dynamic Dashboards and Reports</li>
              <li>Team & Client Collaboration</li>
              <li>Time and Expense</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
