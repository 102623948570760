import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const EnterpriseArchitecture = () => {
  return (
    <div>
      <header className="header">
        <h1>ENTERPRISE ARCHITECTURE AND PROCESS TRANSFORMATION</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              Create, plan and implement digital business solutions using one
              platform for collaborative business process analysis and IT
              solution design. Two best-of-breed products—ARIS and
              Alfabet—converge to make your enterprise digital-ready. The
              enterprise business architecture is the primary bridge between
              ARIS and Alfabet as the graphic below demonstrates. Business
              capabilities can be captured by both ARIS and Alfabet. Alfabet
              captures the operating model and ARIS covers design and management
              of business processes. Synchronized, they ensure that the IT
              portfolio in Alfabet is seen in the business context.
            </p>

            <h5>Key Benefits</h5>
            <ul>
              <li>
                Enterprise context for large business transformation initiatives
              </li>
              <li>Greater agility in providing digital business solutions</li>
              <li>
                Tighter business-IT collaboration from inception to
                implementation of business solutions
              </li>
              <li>
                Clear insight into the impact of change on business and IT
              </li>
              <li>
                Revenue growth through customer focus and market differentiation
              </li>
              <li>Cost reduction through business and IT standardization</li>
            </ul>
            <h5>Core Functionality</h5>
            <ul>
              <li>Product interoperability for process/IT cross-analytics</li>
              <li>
                Federated repository for flexible, customer-configurable system
                of record
              </li>
              <li>
                Automated synchronization of ARIS and Alfabet repositories
              </li>
              <li>
                Aligned ARIS and Alfabet user interfaces for uniform look and
                feelon
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
