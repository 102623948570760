export const Logo = () => (
  <svg width="210mm" height="210mm" version="1.1" className="svgStyles" viewBox="0 0 21000 21000" >
    <g id="Layer_x0020_1">
      <metadata id="CorelCorpID_0Corel-Layer" />
      <g id="_105553373150720">
        <g>
          <path className="fil0" d="M6766.38 7187.39c-230.53,371.44 -188.49,288.22 -106.51,720.97 68.65,362.38 59.91,399.91 317.81,480.34 367.76,114.71 270.63,74.16 492.68,-281.98 252.09,-404.3 252.5,-368.19 135.29,-752.82 -129.55,-425.13 -38.76,-409.63 -405.46,-429.61 -316.38,-17.25 -257.99,-20.2 -433.81,263.1z" />
          <path className="fil0" d="M9117.78 8662.17c217.08,545.74 228.34,466.07 -73.3,870.71 -314.28,421.59 -204.21,354.75 -709.18,255.63 -427.28,-83.87 -355.93,-80.8 -550.33,-537.98 -165.07,-375.47 -232.82,-430.59 54.61,-814.13 276.74,-369.31 232.47,-353.49 670.83,-289.75 521.95,75.89 410.12,19.58 607.37,515.52z" />
          <path className="fil1" d="M8995.25 6067.88c-474.99,-14.65 -376.45,-64.93 -705.09,281.42 -353.8,372.87 -377.71,273.83 -253.53,770.08 121.73,486.47 71.28,443.05 551.41,517.44 551.83,85.5 436.9,111.52 803.17,-322.22 369.87,-437.98 350.86,-319.83 156.49,-857.56 -161.37,-446.42 -83.08,-374.68 -552.45,-389.16z" />
          <path className="fil0" d="M10000.68 4925.91c111.16,151.17 102.88,154.42 21.38,245.3l-146.7 163.59c-333.5,371.9 -271.57,303.59 -767.42,299.77l-245.43 -1.89c-153.73,-3.43 -161.09,1.48 -184.85,-199.42 -18.22,-154.04 -20.93,-164.85 90.26,-229.4 363.82,-211.19 521.92,-277.85 910.37,-360.79 220.88,-47.17 242.06,-40.45 322.39,82.84z" />
          <path className="fil0" d="M8237.96 5636.55c1.19,186.76 -95.74,297.93 -304.46,517.98 -275.39,290.37 -300.33,345.08 -594.61,322.58 -250.44,-19.15 -252.83,-3.89 -256.84,-287.86 1.5,-238.3 38.86,-290.16 338.41,-469.59 304,-182.08 360.03,-222.87 655.24,-242.62 178.18,-11.92 161.25,2.1 162.26,159.51z" />
          <path className="fil0" d="M11046.47 4975.17c394.23,21.38 465.14,45.56 663.1,-160.28 209.58,-217.94 238.28,-225.68 47.34,-367.55 -233.3,-173.33 -179.57,-156.66 -471.19,-154.55 -304.45,2.2 -338.68,8.21 -610.79,144.61 -344.72,172.79 -381.93,158.18 -206.07,346.76 180.73,189.12 242.69,172.85 577.61,191.01z" />
          <path className="fil0" d="M9580.98 4496.31c-119.65,76.61 -233.27,154.78 -364,205.25 -157.51,60.81 -312.84,99.69 -474.48,125.27 -129.71,20.5 -426.17,91.85 -186.65,-48.18 246.36,-144.03 511.46,-252.24 789.42,-353.99 222.99,-81.61 697.15,-223.75 235.71,71.65z" />
          <path className="fil0" d="M8890.27 10713.8c201.54,329.94 244.24,339.79 32.4,545.85 -298.88,290.75 -197.76,252.16 -599.03,116.14 -366.39,-140.18 -339.21,-148.92 -540,-500.38 -187.9,-328.92 -212.8,-269.68 -32.96,-539.19 155.29,-232.71 166.31,-218.23 533.11,-114.55 459.29,129.82 356.44,82.79 606.48,492.13z" />
          <path className="fil0" d="M6740.12 9021.06c0.77,-240.51 -25.71,-228.15 255.02,-136.97 316.45,102.79 248.47,138.78 376.9,460.67 142.17,356.3 207.74,317.81 58.38,659.6 -103.35,236.5 -88.89,242.4 -232.6,126.25 -111.96,-123.98 -226.85,-337.89 -320.33,-562.24 -96.28,-231.09 -134.82,-326.61 -137.37,-547.31z" />
          <path className="fil0" d="M13185.75 5465.11c177.61,332.83 214.99,318.07 47.62,572.02 -167.34,253.89 -178.17,205.87 -558.57,86.76 -463.04,-144.97 -374.55,-58.37 -597.61,-492.28 -182.54,-355.1 -209.38,-310.84 32.7,-576.18 198.82,-217.94 197.98,-202.04 551.87,-82.92 373.19,125.6 315.65,102.21 523.99,492.6z" />
          <path className="fil0" d="M13931.3 6394.24c151.04,249.1 286.03,623.03 326.62,954.22 -3.76,283.3 8.63,249.82 -276.52,159.36 -256.01,-81.23 -251.13,-256.89 -370.15,-563.62 -119.35,-307.63 -165.21,-269.76 -42.95,-566.12 138.21,-335.02 153.13,-329.97 363,16.16z" />
          <path className="fil0" d="M11912.22 6765.82c-314.4,370.61 -356.59,412.82 -139.58,933.3 187.76,450.31 92.95,500.97 655.32,578.63 549.95,75.94 510.87,129.57 789.2,-293.72 248.85,-378.44 287.66,-338.94 90.93,-828.68 -173.06,-430.85 -125.25,-484.63 -604.01,-598.04 -523.61,-124.04 -521.94,-109.66 -791.86,208.51zm336.71 346.69c-144.5,170.33 -163.89,189.74 -64.16,428.96 86.3,206.95 42.73,230.23 301.19,265.93 252.76,34.9 234.79,59.55 362.71,-134.99 114.37,-173.94 132.21,-155.78 41.79,-380.87 -79.53,-198.01 -57.56,-222.73 -277.59,-274.86 -240.66,-57 -239.9,-50.4 -363.94,95.83z" />
          <path className="fil0" d="M10653.6 7274.74c490.16,86.01 466.23,20.54 712.84,607.98 242.22,576.96 163.01,507.44 -151.64,919.32 -370.99,485.6 -285.1,422.1 -935.63,346.38 -572.18,-66.61 -489.9,-120.24 -669.86,-583.92 -237.07,-610.86 -247.26,-516.05 148.66,-963.57 373.76,-422.5 378.29,-416.98 895.63,-326.19zm-87.57 462.11c248.68,43.65 236.53,10.43 361.64,308.45 122.89,292.7 82.7,257.43 -76.94,466.39 -188.2,246.35 -144.63,214.14 -474.65,175.73 -290.28,-33.8 -248.54,-61 -339.84,-296.25 -120.27,-309.89 -125.43,-261.8 75.43,-488.83 189.61,-214.34 191.9,-211.53 454.36,-165.49z" />
          <path className="fil0" d="M10020.31 9500.73l402.07 59.81c217.57,32.35 319.64,28.69 453.03,368.83l51.96 132.5c86.84,221.45 202.15,367.76 -112.23,681.91 -324.88,324.64 -283.01,335.65 -709.9,285.25 -563.34,-66.57 -526,-37.82 -734.1,-441.89 -220.52,-428.22 -139.87,-409.2 94.57,-750.28 216.17,-314.49 313.54,-371.97 554.6,-336.13zm67.4 466.41l158.74 23.61c85.89,12.77 126.18,11.34 178.85,145.62l20.51 52.31c34.29,87.42 79.81,145.2 -44.3,269.22 -128.26,128.16 -111.74,132.51 -280.28,112.62 -222.4,-26.29 -207.66,-14.93 -289.81,-174.46 -87.07,-169.06 -55.22,-161.55 37.34,-296.21 85.33,-124.16 123.78,-146.85 218.95,-132.71z" />
          <path className="fil0" d="M10174.15 5509.9c-333.03,356.18 -386.63,356 -187.73,840.17 175.34,426.84 115.97,446.99 692.92,541.82 574.93,94.49 595.78,124.42 909.5,-298.7 323.48,-436.28 344.48,-470.95 63.75,-922.19 -244.09,-392.35 -207.51,-366.73 -708.73,-421.5 -408.54,-44.63 -465.17,-65.3 -769.71,260.4zm373.91 315.22c-151.31,161.83 -175.67,161.76 -85.3,381.75 79.67,193.94 52.7,203.11 314.85,246.2 261.24,42.93 270.72,56.53 413.27,-135.73 146.97,-198.23 156.51,-213.99 28.96,-419.03 -110.9,-178.27 -94.28,-166.63 -322.03,-191.51 -185.64,-20.29 -211.37,-29.68 -349.75,118.32z" /> <g>
            <path className="fil1" d="M13117.17 11335.69c-33.02,-33.12 -134.35,-321.48 -162.16,-392.13 -45.78,-116.29 -455.34,-1119.75 -468.45,-1182.35l1577.96 631.15c-100.42,64.38 -392.35,211.12 -418.8,260.59 -51.49,96.29 57.55,166.76 136.05,243.97l405.34 405.55c36.7,36.97 105.15,95.38 130.79,134.71 -17.04,28.03 -131.96,144.68 -157.44,155.27 -47.46,-42.61 -89.97,-89.56 -136.08,-135.17 -47.2,-46.7 -88.61,-87.58 -135.43,-134.8 -90.32,-91.11 -175.61,-176.18 -266.78,-266.18 -73.77,-72.83 -152.33,-193.03 -252.4,-130.77 -50.88,31.65 -194.12,339.29 -252.6,410.16zm208.62 -1473.88c-86.72,-25.2 -419.93,-164.45 -532.34,-208.53 -88.61,-34.75 -172.48,-68.37 -259.46,-103.29 -76.2,-30.61 -196.63,-95.01 -282.32,-85.57 -10.7,14.88 -104.03,39.19 -55.17,159.53 166.16,409.31 356.74,872.76 509.63,1274.34 60.42,158.69 228.57,592.78 290.8,728.48 27.17,59.28 102.36,99.76 168.95,44.34 34.77,-28.92 234.83,-403.11 285.29,-477.86l223.86 219.27c57.44,57.77 414.5,427.37 457.29,439.03 76.97,20.96 118.91,-37.71 149.98,-68.85l223.98 -223.88c31.9,-31.58 92,-72.1 70.64,-150.61 -15.09,-55.49 -169.28,-184.26 -216.16,-232.85 -86.12,-89.28 -144.56,-146.21 -222.49,-225.45 -47.16,-47.91 -197.7,-185.78 -218.11,-222.86l391.08 -227.62c58.51,-33.47 152.89,-83.61 95.28,-180.2 -29.19,-48.9 -141.17,-80.46 -204.3,-105.34l-876.43 -352.08z" />
            <path className="fil1" d="M12062.96 10171.3c35.53,-68.27 -14.9,-134.57 -63.06,-149.3 -76.97,-23.53 -119.7,32.81 -150.46,64.04 -41.89,42.52 -200.03,190.05 -218.35,229.48 -32.56,70.1 11,130.3 59.51,146.27 80.73,26.57 122.24,-32.34 153.84,-64.28 42.58,-43.04 198,-186.79 218.52,-226.21zm989.19 -1264.11c-58.95,23.56 -85.06,62.04 -124.59,102.12 -83.62,84.79 -201.82,170.32 -185.91,255.98 10.1,54.41 67.04,102.44 137.4,81.32 44.5,-13.36 195.51,-176.6 233.02,-214.72 31.34,-31.88 91.76,-71.47 76.55,-148.95 -10.85,-55.27 -73.45,-100.94 -136.47,-75.75zm-718.96 -102.12l0 277.87c0,178.96 215.28,167.7 215.28,7.72l0 -308.74c0.04,-152.33 -215.28,-180.77 -215.28,23.15zm-596.83 154.48c-52.62,23.99 -91.59,80.38 -52.33,150.11 16.37,29.09 83.06,88.79 108.98,114.89 90.22,90.84 172.95,208.46 265.69,167.6 44.69,-19.67 85.47,-84.82 50.23,-151.13 -15.29,-28.76 -184.76,-193.44 -220.27,-227.94 -32.02,-31.12 -76.3,-88.2 -152.3,-53.53zm-286.82 662.09c-41.56,19.02 -78.3,55.42 -63.56,123.92 11.14,51.7 55.56,79.29 115.56,80.84 64.96,1.67 329.1,7.47 371.01,-10.28 92.26,-39.12 85.69,-198.26 -53.26,-204.75 -63.97,-2.99 -327.97,-8.87 -369.75,10.27z" />
          </g>
        </g>
        <polygon
          className="fil2"
          points="2755.76,12547.21 3469.39,12547.21 4482.61,14203.37 4489.34,14203.37 4489.34,12547.21 5014.46,12547.21 5014.46,14930.46 4327.77,14930.46 3287.62,13233.91 3280.89,13233.91 3280.89,14930.46 2755.76,14930.46 "
        />
        <path
          className="fil2"
          d="M7279.9 12547.21l434.24 0 1036.78 2383.25 -592.45 0 -205.33 -504.93 -925.7 0 -198.6 504.93 -578.99 0 1030.05 -2383.25zm201.97 693.43l-289.49 740.56 582.35 0 -292.86 -740.56z"
        />
        <polygon
          className="fil2"
          points="10447.47,13011.74 9767.5,13011.74 9767.5,12547.21 11652.56,12547.21 11652.56,13011.74 10972.59,13011.74 10972.59,14930.46 10447.47,14930.46 "
        />
        <polygon
          className="fil2"
          points="12935.07,12547.21 14554.2,12547.21 14554.2,13031.94 13460.19,13031.94 13460.19,13476.27 14493.61,13476.27 14493.61,13961 13460.19,13961 13460.19,14445.73 14614.79,14445.73 14614.79,14930.46 12935.07,14930.46 "
        />
        <path
          className="fil2"
          d="M16031.95 12547.21l787.69 0c191.87,0 373.64,20.2 545.32,63.96 171.67,43.76 319.78,111.08 447.7,205.34 127.91,97.61 228.9,222.16 306.32,373.64 74.06,151.48 111.08,336.62 111.08,555.42 0,191.87 -37.02,363.54 -107.71,508.29 -74.06,148.11 -171.68,272.66 -296.23,370.28 -124.55,100.98 -265.92,178.41 -427.5,228.9 -161.58,50.49 -329.89,77.42 -508.29,77.42l-858.38 0 0 -2383.25zm525.13 1898.52l272.66 0c121.18,0 235.63,-13.46 339.98,-37.03 104.35,-23.56 191.87,-67.32 269.29,-124.54 77.43,-57.23 138.02,-131.29 181.78,-225.54 43.76,-90.88 63.95,-205.33 63.95,-339.98 0,-117.82 -20.19,-218.8 -63.95,-306.32 -43.76,-84.16 -104.35,-154.85 -178.41,-212.07 -74.06,-57.23 -161.58,-97.62 -259.2,-127.92 -100.98,-26.93 -205.33,-40.39 -316.42,-40.39l-309.68 0 0 1413.79z"
        />
        <path
          className="fil3"
          d="M6248.83 15587.91c-95.47,-109.11 -204.57,-146.61 -340.96,-146.61 -196.05,0 -390.4,109.1 -390.4,342.66 0,441.54 618.84,252.31 618.84,593.27 0,141.5 -138.08,219.92 -259.13,219.92 -126.15,0 -223.32,-57.96 -274.47,-146.61l-110.81 80.12c92.06,126.16 233.56,179.01 380.17,179.01 190.94,0 397.22,-129.57 397.22,-349.49 0,-434.72 -618.84,-262.54 -618.84,-588.15 0,-151.73 131.27,-218.22 257.42,-218.22 102.29,0 180.71,39.21 233.56,117.63l107.4 -83.53z"
        />
        <polygon
          className="fil3"
          points="7379.11,16159.02 7830.88,15471.98 7677.45,15471.98 7317.74,16041.38 6958.02,15471.98 6804.59,15471.98 7256.36,16159.02 7256.36,16678.98 7379.11,16678.98 "
        />
        <path
          className="fil3"
          d="M9123.12 15587.91c-95.47,-109.11 -204.57,-146.61 -340.96,-146.61 -196.05,0 -390.4,109.1 -390.4,342.66 0,441.54 618.84,252.31 618.84,593.27 0,141.5 -138.08,219.92 -259.13,219.92 -126.15,0 -223.32,-57.96 -274.47,-146.61l-110.81 80.12c92.06,126.16 233.56,179.01 380.17,179.01 190.94,0 397.22,-129.57 397.22,-349.49 0,-434.72 -618.84,-262.54 -618.84,-588.15 0,-151.73 131.27,-218.22 257.42,-218.22 102.29,0 180.71,39.21 233.56,117.63l107.4 -83.53z"
        />
        <polygon
          className="fil3"
          points="10253.4,15584.5 10657.44,15584.5 10657.44,15471.98 9726.62,15471.98 9726.62,15584.5 10130.65,15584.5 10130.65,16678.98 10253.4,16678.98 "
        />
        <polygon
          className="fil3"
          points="11441.65,16107.87 12026.39,16107.87 12026.39,15995.36 11441.65,15995.36 11441.65,15584.5 12067.31,15584.5 12067.31,15471.98 11318.9,15471.98 11318.9,16678.98 12087.77,16678.98 12087.77,16566.46 11441.65,16566.46 "
        />
        <polygon
          className="fil3"
          points="12991.31,15471.98 12803.78,15471.98 12803.78,16678.98 12926.53,16678.98 12926.53,15635.64 12929.94,15635.64 13366.36,16678.98 13431.15,16678.98 13867.58,15635.64 13870.99,15635.64 13870.99,16678.98 13993.73,16678.98 13993.73,15471.98 13806.2,15471.98 13398.76,16453.95 "
        />
        <path
          className="fil3"
          d="M15473.49 15587.91c-95.47,-109.11 -204.57,-146.61 -340.96,-146.61 -196.05,0 -390.4,109.1 -390.4,342.66 0,441.54 618.84,252.31 618.84,593.27 0,141.5 -138.08,219.92 -259.13,219.92 -126.15,0 -223.32,-57.96 -274.47,-146.61l-110.81 80.12c92.06,126.16 233.56,179.01 380.17,179.01 190.94,0 397.22,-129.57 397.22,-349.49 0,-434.72 -618.84,-262.54 -618.84,-588.15 0,-151.73 131.27,-218.22 257.42,-218.22 102.29,0 180.71,39.21 233.56,117.63l107.4 -83.53z"
        />
        <path
          className="fil0"
          d="M2754.36 16019.52l1623.22 0 0 111.84 -1623.22 0 0 -111.84zm13853.8 0l1623.22 0 0 111.84 -1623.22 0 0 -111.84z"
        />
      </g>
    </g>
  </svg>
)