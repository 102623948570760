import { Navbar, Container, Nav, NavDropdown, Row } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { Component } from "react";

import "../scss/shared/app-nav.scss";
import DropdownColumn from "./subcomponents/dropdown-column";
import NavLink from "./subcomponents/nav-link";
import { digitalLinks, advisoryLinks, cloudLinks } from "../data/nav-links";
import { Logo } from "./subcomponents/logo";
import AppBanner from "./app-banner";

interface appState {
  scrolled: boolean;
}
class AppNav extends Component {
  public state: appState;
  constructor(props: any) {
    super(props);
    this.state = { scrolled: false };
  }

  componentDidMount() {
    const self = this;
    window.addEventListener("scroll", () => {
      self.setState({ scrolled: window.scrollY > 5 });
    });
  }

  render() {
    const { scrolled } = this.state;
    return (
      <div className="AppNav">
        <AppBanner />
        <Row>
          <Navbar bg="light" expand="lg" className={`${scrolled ? "scrolled" : ""}`}>
            <Container fluid="sm">
              <LinkContainer to="/">
                <Navbar.Brand> <Logo /> </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto">
                  <NavLink to="/" text="Home" scrolled={scrolled} />
                  <NavLink to="/about-us" text="About-us" scrolled={scrolled} />
                  <NavDropdown className={`${scrolled ? "link-scrolled" : ""}`} title="Services" id="multi-nav-dropdown">
                    <Row className="multi-column-width mx-auto">
                      <DropdownColumn title="Digital Solutions" links={digitalLinks} />
                      <DropdownColumn title="Cloud Services" links={cloudLinks} />
                      <DropdownColumn title="IT Advisory" links={advisoryLinks} />
                    </Row>
                  </NavDropdown>
                  <NavLink to="/industries" text="Industries" scrolled={scrolled} />
                  <NavLink to="/contact-us" text="Contact-us" scrolled={scrolled} />
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
        </Row>
      </div>
    );
  }
}

export default AppNav;
