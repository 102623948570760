import { Col, Container, Row } from "react-bootstrap";
import "../../scss/services/services.scss";

export const ServiceManagementSystem = () => {
  return (
    <div>
      <header className="header">
        <h1>SERVICE MANAGEMENT SYSTEM</h1>
      </header>
      <Container>
        <Row>
          <Col className="pt-4">
            <p>
              Nated Systems offers a leading customer support ticketing software
              that serves all small, medium, and enterprise-level business with
              its 4 different products. Help Desk Software - A ticketing
              software to manage customer support over various channels like
              email, web portal, VOIP, live chat, API, Facebook, Twitter and
              more. It comes with unique features like ticket billing, task
              management, online forums, help desk gamification and more.
              Satellite Help Desk Software - A multi company or multi brand
              customer support management software. Best fit if you are running
              multiple websites, brands or products and wanted to offer
              different branded web portals for each of them having single back
              end to manage everything at one place. Service Desk Software -
              ITIL/ITSM PinkVerify Certified product that offers all features
              from help desk and satellite help desk and additionally it offers
              ITIL compliant features CMDB, Asset Management, Incident
              Management, Problem Management, Solution or Knowledgeable
              Management, Change Management and Release Management. Live Chat
              Software - A secure, real-time multi-company live chat messaging
              platform that improves customer engagement by helping sales teams
              to convert website visitors into paying customers. Further, It
              also enhances customer support team productivity by giving them
              the ability to deliver quick and reliable support.
            </p>
            <h5>Key Features</h5>
            <ul>
              <li>ISO 27001:2013 Certified</li>
              <li>GDPR & EU Data Protection</li>
              <li>PinkVERIFY™ Certified</li>
              <li>Easy to Setup and Get Started</li>
              <li>90% Code Open Easy To Integrate</li>
              <li>Reliable and Quick Support</li>
              <li>All in One Solution</li>
            </ul>
          </Col>
        </Row>
      </Container>
    </div>
  );
};
