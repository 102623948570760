
import { FunctionComponent } from 'react';
import { FieldError } from 'react-hook-form';

interface FormErrorProps {
  error: FieldError | undefined;
}

const FormError: FunctionComponent<FormErrorProps> = ({ error }) => {
  const { message } = error || { message: '' };
  return (message && <small className='text-danger form-text'>{message}</small>) || null
}

export default FormError;