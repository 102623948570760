import { Container } from "react-bootstrap";
import "../scss/home/app-header.scss";
import Typist from "react-typist";
import Slider, { Settings } from "react-slick";

const AppHeader = () => {
  var settings: Settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    afterChange: () => {},
    beforeChange: () => {},
  };

  return (
    <header className="App-header">
      <Container fluid="sm">
        <Typist cursor={{ hideWhenDone: true, blink: true, show: false }}>
          <h2>DIGITAL</h2>
          <p>We create a successful Digital Transformation strategy.</p>
        </Typist>
      </Container>
    </header>
  );
};

export default AppHeader;
